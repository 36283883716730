import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivationEnd, ActivationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppSeoService {
  hostName = "https://www.mein-terrassendach24.de";
  defaultKeywords = "Terrassenüberdachung, Terrassendach, Wandanschluss, Freistehend, Schiebedach, Flachdach, Gartenzimmer";
  defaultDescription = "Ihr Fachhändler für Terrassenüberdachungen.";

  defaultTitle = "Terrassenüberdachung - Mein-Terrassendach24";
  defaultTitleAppend = "Mein-Terrassendach24";
  pageTitle: string = "";

  constructor(
    @Inject(DOCUMENT) private dom,
    private router: Router,

    private title: Title, private meta: Meta) { }


  boot() {

    this.router.events.pipe(
      filter(event => event instanceof ActivationStart))

      .subscribe((event: ActivationStart) => {
        this.updateDescription(this.defaultDescription);
        this.updateKeyword(this.defaultKeywords);
      });

    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd && event.snapshot.children.length == 0))

      .subscribe((event: ActivationEnd) => {

        const data = event.snapshot.data;

        //Set Canonical URL
        if (data.canonical) {
          this.updateCanonicalUrl(this.hostName + data.canonical);
        } else {
          this.updateCanonicalUrl(this.hostName + this.router.url);
        }

        this.pageTitle = data && data.title ? data.title : this.defaultTitle;
        const title = data && data.title ? `${data.title} - ${this.defaultTitleAppend}` : this.defaultTitle;
        this.title.setTitle(title)

        //this.updateDescription(this.defaultDescription);
        //this.updateKeyword(this.defaultKeywords);
      });

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    //   map(() => this.activeRoute),
    //   map(route => route.firstChild),
    //   switchMap(route => route.data),
    //   map((data) => {
    //     return data && data.title ? `${data.title} • ${this.default_title}` : this.default_title;
    //   })
    // ).subscribe((current_title) => this.title.setTitle(current_title));
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateKeyword(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }
}
