import { Injectable } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MainProductDto } from "../model/MainProductDto";
import { ProductSectionDto } from "../model/ProductSectionDto";
import { ProductGroupType } from "../model/ProductGroupType";
import { ProductGroupDto } from "../model/ProductGroupDto";
import { ProductOptionDto } from "../model/ProductOptionDto";
import { checkNumber } from "../dynamic-product-form/validators/number-validator.directive";
import { group } from "@angular/animations";

@Injectable()
export class ProductControlsService {

  constructor(private fb: FormBuilder) { }

  orderProduct(mainProduct: MainProductDto) {
    if (mainProduct.ProductSections) {
      if (mainProduct.ProductSections.length > 1) {
        mainProduct.ProductSections = mainProduct.ProductSections.sort((a, b) => a.Order - b.Order);
      }

      mainProduct.ProductSections.forEach(section => this.orderSection(section));
    }
  }

  private orderSection(section: ProductSectionDto) {
    if (section.ProductGroups.length > 1) {
      section.ProductGroups = section.ProductGroups.sort((a, b) => a.Order - b.Order);
    }
    section.ProductGroups.forEach(productGroup => {
      if (productGroup.ProductOptions && productGroup.ProductOptions.length > 1) {
        productGroup.ProductOptions = productGroup.ProductOptions.sort((a, b) => a.Order - b.Order);
      }
    });

    section.ChildSections.forEach(childSection => this.orderSection(childSection));
  }

  toFormGroup(mainProduct: MainProductDto): FormGroup {

    let productConfig = this.fb.group({});

    let formGroup: any = this.fb.group({
      productConfig: productConfig
    });

    mainProduct.ProductSections.forEach(section => this.processSection(mainProduct, productConfig, section));

    return formGroup;
  }

  processSection(mainProduct: MainProductDto, formGroup: FormGroup, section: ProductSectionDto) {

    let sectionFormGroup = this.fb.group({});

    section.ProductGroups.forEach(productGroup => {

      switch (productGroup.Type) {
        case ProductGroupType.Select:
        case ProductGroupType.Radio:
          let selectOrRadioGroupControl = this.fb.group({});
          let defaultOption = this.getSelectOrRadioDefaultValue(productGroup);
          productGroup.SelectedProductOption = defaultOption;

          let validators = this.getValidatorsForSelectOrRadio(productGroup);

          let isAvailableForProduct = this.isAvailableForProduct(productGroup, null);
          let isDisabledByParentInput = this.isDisabledByParentInput(mainProduct, productGroup, null);

          let selectControl: FormControl = this.fb.control(
            { value: defaultOption ? defaultOption.Id : null, disabled: !isAvailableForProduct || isDisabledByParentInput },
            validators
          );
          // Add one form control for all the options of the select element and use the ID of the parent group for the ID of the control
          selectOrRadioGroupControl.addControl(productGroup.Id, selectControl);

          sectionFormGroup.addControl(productGroup.Id, selectOrRadioGroupControl);
          /*
          let defaultOptions = productGroup.ProductOptions.filter(
            po => po.IsDefaultOption === true
          );
          let defaultOption =
            defaultOptions.length === 1 ? defaultOptions[0] : null;

          productGroup.ProductOptions.forEach(option => {
            productGroupFormGroup[option.Id] = option.Required
              ? new FormControl(defaultOption, Validators.required)
              : new FormControl(defaultOption);
          });
          */
          break;
        case ProductGroupType.Input:
        case ProductGroupType.OptionalInput:
          let inputGroupFormGroup = this.fb.group({});
          productGroup.ProductOptions.forEach(option => {
            let validators = this.getValidatorsForInput(option);
            let defaultValue = this.isAvailableForProduct(productGroup, option) ? option.DefaultIntValue : null;
            let inputControl: FormControl = this.fb.control(
              {
                value: defaultValue, //option.DefaultIntValue,
                disabled: !this.isAvailableForProduct(productGroup, option) || (productGroup.Type == ProductGroupType.OptionalInput && !option.Required && !option.Checked)
              },
              validators
            );

            inputGroupFormGroup.addControl(option.Id, inputControl);

            let inputControlChecked: FormControl = this.fb.control(
              {
                value: option.Checked,
                disabled: !this.isAvailableForProduct(productGroup, option) || option.Required || productGroup.Required
              },
              validators
            );

            inputGroupFormGroup.addControl(option.Id + '_checked', inputControlChecked);
          });

          sectionFormGroup.addControl(productGroup.Id, inputGroupFormGroup);
          break;

        default:
          /*           formGroup[productGroup.Id] = productGroup.Required
            ? new FormControl(
                productGroup.ProductOptions[0],
                Validators.required
              )
            : new FormControl(productGroup.SelectedProductOption);

          productGroup.ProductOptions.forEach(option => {
            formGroup[option.Id] = option.Required
              ? new FormControl(null, Validators.required)
              : new FormControl(null);
          }); */
          let defaultGroupFormGroup = this.fb.group({});
          sectionFormGroup.addControl(productGroup.Id, defaultGroupFormGroup);

          break;
      }

      /*

      */
    });

    formGroup.addControl(section.Id, sectionFormGroup);

    section.ChildSections.forEach(childSection => this.processSection(mainProduct, sectionFormGroup, childSection));
  }

  private getValidatorsForSelectOrRadio(group: ProductGroupDto) {
    let validators = [];

    if (group.Required) {
      validators.push(Validators.required);
    }

    return validators;
  }

  private getValidatorsForInput(option: ProductOptionDto) {
    let validators = [];

    if (option.Required) {
      validators.push(Validators.required);
    }

    if (option.MinValue !== null) {
      validators.push(Validators.min(option.MinValue));
    }

    if (option.MaxValue !== null) {
      validators.push(Validators.max(option.MaxValue));
    }

    validators.push(checkNumber());

    return validators;
  }

  isAvailableForProduct(group: ProductGroupDto, option: ProductOptionDto) {

    if (group && group.ProductAssignment && group.ProductAssignment.IsNotAvailabeForProduct) {

      return false;
    }

    if (option && option.ProductAssignment && option.ProductAssignment.IsNotAvailabeForProduct) {

      return false;
    }

    return true;
  }

  isDisabledByParentInput(mainProduct: MainProductDto, group: ProductGroupDto, option: ProductOptionDto) {

    if (!group || !group.ParentGroupInputRequired || !group.ParentGroupId) {

      return false;
    }

    let parentGroupHasInputValue = true;
    let parentGroup = this.findItemById<ProductGroupDto>(mainProduct.ProductSections, 'Id', group.ParentGroupId);

    // TODO: support input fields, not only radio or selects
    if (parentGroup.SelectedProductOption) {
      parentGroupHasInputValue = false;
    }

    return parentGroupHasInputValue;
  }

  getNotAvailableForProductText(group: ProductGroupDto, option: ProductOptionDto) {

    if (this.isAvailableForProduct(group, option)) {
      return '';
    }

    if (group && group.ProductAssignment && group.ProductAssignment.NotAvailableDescription) {
      return group.ProductAssignment.NotAvailableDescription;
    } else if (option && option.ProductAssignment && option.ProductAssignment.NotAvailableDescription) {

      return option.ProductAssignment.NotAvailableDescription;
    }

    return 'Für dieses Produkt nicht verfügbar';
  }

  findItemById<T>(object: any, keyPropertyName: string, id: string): T {

    if (object && typeof object === 'object' && object.hasOwnProperty(keyPropertyName) && object[keyPropertyName] === id) {

      return object;
    }

    var result = null;

    for (var p in object) {

      if (object.hasOwnProperty(p) && typeof object[p] === 'object') {

        result = this.findItemById(object[p], keyPropertyName, id);

        if (result !== null) {

          return result;
        }
      }
    }

    return result;
  }

  private getSelectOrRadioDefaultValue(productGroup: ProductGroupDto): ProductOptionDto {
    if ((productGroup && productGroup.ProductAssignment && productGroup.ProductAssignment.IsNotAvailabeForProduct)
      || !productGroup.Required) {
      return null;
    }

    if (productGroup.ProductOptions) {
      let defaultOptions = productGroup.ProductOptions.filter(po => po.IsDefaultOption === true);

      var defaultAvailable = defaultOptions.length > 0;
      var setNextAvailableOptionAsDefault = false;

      for (var i = 0; i < productGroup.ProductOptions.length; i++) {
        var option = productGroup.ProductOptions[i];

        if (option.ProductAssignment && option.ProductAssignment.IsNotAvailabeForProduct) {
          if (option.IsDefaultOption) {
            setNextAvailableOptionAsDefault = true;
          }
        } else if (option.IsDefaultOption || !defaultAvailable || setNextAvailableOptionAsDefault) {
          return option;
        }
      }
    }

    return null;
  }
}
