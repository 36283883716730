import { CommonModule, IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { OverlayModule } from "@angular/cdk/overlay";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbCarouselModule, NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { HoverClassDirective } from "./animations/hover-class.directive";
import { ComponentPageHeaderComponent } from "./component-page-header/component-page-header.component";
import { FooterComponent } from "./footer/footer.component";
import { HoverOverlayComponent } from "./hover-overlay/hover-overlay.component";
import { MailService } from "./mail-service";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { Mtd24CurrencyPipe } from "./pipes/mtd24-currency.pipe";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    MatBottomSheetModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatTooltipModule,
    OverlayModule,
    MatChipsModule,
    NgbCarouselModule,
    NgbPopoverModule,
    NgOptimizedImage,
    FontAwesomeModule,
    Mtd24CurrencyPipe,
    NavBarComponent,
    ComponentPageHeaderComponent,
    FooterComponent,
    HoverClassDirective,
    HoverOverlayComponent,
  ],
  providers: [
    MailService,
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return `${environment.imagesEndpoint}/${config.src}?width=${config.width}&format=webp`;
      },
    },
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    MatBottomSheetModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatTooltipModule,
    MatChipsModule,
    OverlayModule,
    NgbCarouselModule,
    NavBarComponent,
    ComponentPageHeaderComponent,
    FooterComponent,
    HoverClassDirective,
    Mtd24CurrencyPipe,
    NgOptimizedImage,
    FontAwesomeModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, // Tells Angular we will have custom tags in our templates
  ],
})
export class SharedMinimumModule {}
