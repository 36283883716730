import { MainProductDto } from "./MainProductDto";
import { ProductOptionDto } from "./ProductOptionDto";
import { ProductOverviewItemType } from "./ProductOverviewItemType";
import { DiscountItemDto } from "./DiscountItemDto";
import { ProductBaseDto } from "./ProductBaseDto";
import { DiscountCalculationMethod } from "./DiscountCalculationMethod";
import { formatNumber } from "@angular/common";

export class ProductOverviewItem {

    Type: ProductOverviewItemType;
    IsForMainProduct: boolean;
    LineItemNumber: number;
    Description: string;
    ProductId: string;
    Quantity: number;
    PricePerUnit: number;
    AvailableOnRequest: boolean;
    //BeforeTaxAmount: number;
    //DiscountPerUnit: number = 0;
    get DiscountAmount(): number {
        let extendedAmount = this.Quantity * this.PricePerUnit;

        return extendedAmount;
    };
    //Tax: number;
    //TaxRate: number;
    get ExtendedAmount(): number {
        let extendedAmount = this.Quantity * this.PricePerUnit;

        return extendedAmount;
    };

    get PricePerUnitText(): string {
        let text;

        if (this.PricePerUnit > 0) {
            text = formatNumber(this.PricePerUnit, 'de-de', '1.0-0') + ",- €";
            text = text.replace(/&nbsp;/g, ' ');
        }
        else if (this.PricePerUnit == 0) {
            text = 'inklusive';
        }
        else if (this.PricePerUnit < 0) {
            text = 'Rabatt'
        }
        else if (this.AvailableOnRequest) {
            text = '-';
        }

        return text;
    };

    /**
     *
     */
    constructor() {
    }

    static createFromMainProduct(mainProduct: MainProductDto) {

        let poi = new ProductOverviewItem();
        poi.IsForMainProduct = true;
        poi.Type = ProductOverviewItemType.MainProduct;
        poi.LineItemNumber = 1;
        poi.Description = mainProduct.Name;
        poi.Quantity = 1;
        poi.ProductId = mainProduct.Id;
        poi.PricePerUnit = mainProduct.Price || 0;
        poi.AvailableOnRequest = mainProduct.AvailableOnRequest;

        return poi;
    }

    static createFromProductOption(productOption: ProductOptionDto, lineItemNumber: number) {

        let poi = new ProductOverviewItem();
        poi.IsForMainProduct = false;

        poi.Type = ProductOverviewItemType.ProductOption;
        poi.LineItemNumber = lineItemNumber;
        poi.Description = productOption.Name;
        poi.Quantity = productOption.IntValue || 1;
        poi.ProductId = productOption.Id;
        poi.PricePerUnit = productOption.Price || 0;
        poi.AvailableOnRequest = productOption.AvailableOnRequest;

        return poi;
    }

    static createFromDiscountItem(discountItem: DiscountItemDto, product: ProductBaseDto, quantity: number, lineItemNumber: number, isForMainProduct: boolean): ProductOverviewItem {

        let poi = new ProductOverviewItem();

        poi.IsForMainProduct = isForMainProduct;
        poi.LineItemNumber = lineItemNumber;
        poi.Type = ProductOverviewItemType.ProductDiscountItem;
        poi.Description = discountItem.Name;
        poi.Quantity = quantity || 1;;
        poi.ProductId = product.Id;
        poi.AvailableOnRequest = false;

        switch (discountItem.DiscountCalculationMethod) {
            case DiscountCalculationMethod.FixedAmount:
                poi.PricePerUnit = discountItem.DiscountAmount * -1;
                break;
            case DiscountCalculationMethod.PercentageFromListPrice:
                poi.PricePerUnit = Math.ceil(product.ListPrice * discountItem.DiscountPercentage / 100) * -1;
                break;
            case DiscountCalculationMethod.PercentageFromPrice:
                poi.PricePerUnit = Math.ceil(product.Price * discountItem.DiscountPercentage / 100) * -1;
                break;
            default:
                poi.PricePerUnit = 0;
                break;
        }

        return poi;
    }
}