import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectionPositionPair,
  ScrollDispatcher,
  ViewportRuler
} from "@angular/cdk/overlay";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subject, fromEvent } from "rxjs";
import { debounceTime, filter, share, startWith, switchMap, switchMapTo, takeUntil } from "rxjs/operators";

@Component({
  selector: "itcw-hover-overlay",
  templateUrl: "./hover-overlay.component.html",
  styleUrls: ["./hover-overlay.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CdkConnectedOverlay],
})
export class HoverOverlayComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() cdkOverlayOrigin: CdkOverlayOrigin;
  @Input() positions?: ConnectionPositionPair[];

  @Output() close = new EventEmitter<any>();
  @Output() open = new EventEmitter<any>();

  @ViewChild("overlayContainer") overlayContainer: ElementRef;
  @ViewChild("cdkConnectedOverlay") overlay: CdkConnectedOverlay;

  @HostListener("document:click", ["$event"])
  handleClick(event: any) {
    if (
      event.target.nodeName === "A" ||
      (event.target.className &&
        event.target.className.includes &&
        (event.target.className.includes("badge ") ||
          event.target.className.includes("mat-icon") ||
          event.target.className === "mat-button-wrapper"))
    ) {
      this.isOpened = false;
      this.changeDetectorRef.detectChanges();
    }
    //console.log("Clicked!");
  }

  // _shouldHide: boolean = false;
  // get shouldHide(): boolean {
  //     return this._shouldHide;
  // }

  // @Input() set shouldHide(value: boolean) {
  //     this._shouldHide = value;
  //     this.isOpened = true;
  // }

  isOpened = false;
  destroy$ = new Subject<any>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _scrollDispatcher: ScrollDispatcher,
    private _ngZone: NgZone,
    private _viewportRuler: ViewportRuler,
  ) {}

  ngOnInit(): void {
    const CdkOverlayOriginEl = this.cdkOverlayOrigin.elementRef.nativeElement;

    // open popup if mouse stopped in CdkOverlayOriginEl (for short time).
    // If user just quickly got over CdkOverlayOriginEl element - do not open
    const open$ = fromEvent(CdkOverlayOriginEl, "mouseenter").pipe(
      filter(() => !this.isOpened),
      switchMap((enterEvent) =>
        fromEvent(document, "mousemove").pipe(
          startWith(enterEvent),
          debounceTime(25),
          filter(
            (event) =>
              CdkOverlayOriginEl === event["target"] || CdkOverlayOriginEl === event["target"]["parentElement"],
          ),
        ),
      ),
      share(),
    );

    open$.pipe(takeUntil(this.destroy$)).subscribe(() => this.changeState(true));

    // close if mouse left the CdkOverlayOriginEl and dialog(after short delay)
    const close$ = fromEvent(document, "mousemove").pipe(
      debounceTime(100),
      filter(() => this.isOpened),
      filter((event) => this.isMovedOutside(CdkOverlayOriginEl, this.overlayContainer, event)),
    );

    open$.pipe(takeUntil(this.destroy$), switchMapTo(close$)).subscribe(() => {
      this.changeState(false);
    });
  }

  ngAfterViewInit(): void {
    //this.overlay.hasBackdrop = true;

    // const positions = [
    //   new ConnectionPositionPair({ originX: 'center', originY: 'bottom' }, { overlayX: 'center', overlayY: 'top' }, undefined, this.offsetY),
    // ];

    if (this.positions) {
      this.overlay.positions = this.positions;
    }

    //this.overlay.scrollStrategy = new CloseScrollStrategy(this._scrollDispatcher, this._ngZone, this._viewportRuler, {threshold: 200});

    //this.overlay.positionChange.subscribe((c) => console.log(c));
    // const positionStrategy = this.overlay.positions().position()
    // .flexibleConnectedTo(this.getConnectedElement())
    // .withPositions(positions)
    // .withFlexibleDimensions(false)
    // .withPush(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  connectedOverlayDetach() {
    this.changeState(false);
  }

  private changeState(isOpened: boolean) {
    this.isOpened = isOpened;
    isOpened ? this.open.emit() : this.close.emit();
    this.changeDetectorRef.markForCheck();
  }

  private isMovedOutside(CdkOverlayOriginEl, dialog, event): boolean {
    return !(CdkOverlayOriginEl.contains(event["target"]) || dialog.nativeElement.contains(event["target"]));
  }
}
