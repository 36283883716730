import { animate, state, style, transition, trigger } from "@angular/animations";
import { CdkOverlayOrigin, ConnectionPositionPair } from "@angular/cdk/overlay";
import { AsyncPipe, NgIf, NgOptimizedImage } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener
} from "@angular/core";
import { MatAnchor, MatIconAnchor, MatIconButton } from "@angular/material/button";
import { MatDivider } from "@angular/material/divider";
import { MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatListItem, MatListItemMeta, MatListItemTitle, MatNavList } from "@angular/material/list";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { AppSettings } from "../../model/AppSettings";
import { AppSeoService } from "../../shared-services/app-seo.service";
import { AppSettingsService } from "../../shared-services/app-settings-service";
import { HoverOverlayComponent } from "../hover-overlay/hover-overlay.component";

@Component({
  selector: "itcw-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger("backdropOpenClose", [
      // ...
      state(
        "backdropOpen",
        style({
          opacity: 0.4,
        }),
      ),
      state(
        "backdropClosed",
        style({
          opacity: 0.0,
        }),
      ),
      transition("backdropOpen => backdropClosed", [animate("200ms ease-out")]),
      transition("backdropClosed => backdropOpen", [animate("200ms ease-in")]),
    ]),
  ],
  standalone: true,
  imports: [
    RouterLink,
    NgOptimizedImage,
    NgIf,
    CdkOverlayOrigin,
    HoverOverlayComponent,
    MatNavList,
    MatListItem,
    MatListItemTitle,
    MatIconButton,
    MatListItemMeta,
    MatIcon,
    MatSuffix,
    MatAnchor,
    RouterLinkActive,
    MatIconAnchor,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatDivider,
    AsyncPipe,
  ],
})
export class NavBarComponent {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  appSettings: AppSettings = null;
  promotions: Map<string, string> = new Map<string, string>();

  submenuPositionAccount = [
    new ConnectionPositionPair(
      { originX: "center", originY: "bottom" },
      { overlayX: "center", overlayY: "top" },
      undefined,
      17,
    ),
    new ConnectionPositionPair(
      { originX: "end", originY: "bottom" },
      { overlayX: "end", overlayY: "top" },
      undefined,
      17,
    ),
  ];

  submenuPositionTerraceRoof = [
    new ConnectionPositionPair(
      { originX: "start", originY: "bottom" },
      { overlayX: "start", overlayY: "top" },
      undefined,
      -1,
    ),
  ];

  isOpenedUser = false;
  isOpenedTerraceRoof = false;
  isOpenedTerraceRoofFlachdach = false;
  isOpenedTerraceRoofFreistehend = false;

  backdropHeight: number = 0;
  showBackdrop = false;
  navBarFocus = false;
  backdropDisplay: "block" | "none" = "none";

  constructor(
    public appTitleService: AppSeoService,
    public appSettingsService: AppSettingsService,
    private authorizeService: AuthorizeService,
    private changeRef: ChangeDetectorRef,
  ) {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map((u) => u && u.name));

    this.appSettingsService.appSettingsObs.subscribe((appSettings) => {
      this.appSettings = appSettings;

      let promotions: Map<string, string> = new Map<string, string>();

      if (this.appSettings && this.appSettings.Promotions) {
        const currentDate = new Date();
        this.appSettings.Promotions.filter(
          (p) => p.Enabled && p.StartDate <= currentDate && p.EndDate >= currentDate,
        ).forEach((p) => promotions.set(p.ProductCategory, p.Name));
      }
      this.promotions = promotions;
    });
  }

  navItemFocus() {
    this.showBackdrop = true;
    this.backdropHeight = window.document.body.scrollHeight;
  }

  navItemFocusOut(force: boolean = false) {
    if (!this.navBarFocus || force) {
      this.showBackdrop = false;
    }
  }

  public backdropStart(): void {
    this.backdropHeight = window.document.body.scrollHeight;
    this.backdropDisplay = "block";
  }

  public backdropDone(): void {
    if (this.showBackdrop) {
      this.backdropDisplay = "block";
    } else {
      this.backdropHeight = 0;
      this.backdropDisplay = "none";
    }
    this.changeRef.detectChanges();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.showBackdrop) {
      this.backdropHeight = window.document.body.scrollHeight;
    }
  }

  fixDisappearIOSBug() {
    const styleNode = document.createElement("style");
    styleNode.type = "text/css";
    styleNode.id = "panel-fix";
    styleNode.appendChild(document.createTextNode(".mat-menu-panel{overflow: initial !important;}"));
    document.getElementsByTagName("head")[0].appendChild(styleNode);
    setTimeout(() => {
      styleNode.remove();
    }, 500);
  }

  // promotionExist(productCategory: string) {
  //   let exist = false;

  //   if (this.appSettings && this.appSettings.Promotions) {
  //     const currentDate = new Date();
  //     exist = this.appSettings.Promotions.some(p => (p.Enabled && p.ProductCategory === productCategory
  //       && p.StartDate > currentDate && p.EndDate > currentDate));
  //   }
  //   console.log(productCategory);

  //   return exist;
  // }
}
