import { Injectable } from "@angular/core";
import { DataBaseService } from "./data-base.service";
import { HttpClient } from "@angular/common/http";
import { Subject } from 'rxjs';
import { CustomerDto } from "../model/CustomerDto";



@Injectable()
export class CustomerDataService extends DataBaseService {


    private ngUnsubscribe: Subject<void> = new Subject();

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getCustomer(): Promise<CustomerDto> {

        let url = `${this.apiEndpoint}/customers`;
        let resultPromise = this.httpClient.get<CustomerDto>(url).toPromise();

        return resultPromise;
    }
    saveCustomer(customer: CustomerDto) {

        let url = `${this.apiEndpoint}/customers`;
        let resultPromise = this.httpClient.post<string>(url, customer).toPromise();

        return resultPromise;
    }
}
