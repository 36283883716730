import { Component, OnInit } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
    selector: 'itcw-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, NgOptimizedImage]
})
export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();
  
  constructor() { }

  ngOnInit() {
  }

}
