<header class="header">
  <div
    class="container-fluid px-0 mat-elevation-z0 full-width-background-row-dark position-relative"
    style="z-index: 4">
    <div class="container-xl px-0 mat-elevation-z0">
      <div class="row g-0">
        <div class="col">
          <header class="header py-3 py-md-5 px-3 px-xl-0 d-flex flex-wrap justify-content-center">
            <div class="d-flex mb-0 mb-md-0 py-3 py-md-0">
              <div class="mein-terrassendach24-header-logo">
                <a title="Mein-Terrassendach-24 - Startseite" routerLink="/">
                  <img
                    alt="Mein-Terrassendach24 Logo"
                    title="Mein-Terrassendach24"
                    src="../../../assets/img/logos/mein-terrassendach24-logo.svg" />
                </a>
              </div>
            </div>

            <div class="d-none d-sm-flex flex-nowrap header-logos py-3 py-md-0">
              <img
                class="ms-4"
                alt="Trusted Shop"
                title="Trusted Shop"
                ngSrc="logos/ts.png"
                width="45"
                height="45"
                priority />
              <img
                class="mx-2"
                alt="SSL verschlüsselt"
                title="SSL verschlüsselt"
                src="../../../assets/img/logos/ssl.svg" />
            </div>
            <div class="d-none d-sm-block ms-sm-auto header-item text-start text-end-md py-3 py-md-0">
              <a class="" href="tel:041857929031">
                <i class="item-title" class="fa fa-phone" aria-hidden="true">
                  <span class="phone-number">04185 792 90 31</span>
                </i>
                <br />
                <span class="text-end item-subtitle">Mo. - Fr. 9 - 18 Uhr</span>
              </a>
            </div>

            <div class="d-flex flex-wrap py-0 py-sm-3 py-md-3 py-lg-0">
              <div class="flex-even">
                <div class="d-flex flex-nowrap">
                  <div class="header-item">
                    <a class="" routerLink="/ueber-uns/ueber-uns">
                      <i class="item-title" class="fa fa-info-circle" aria-hidden="true"></i>
                      <br />
                      <span class="item-subtitle">Service</span>
                    </a>
                  </div>

                  <div class="header-item">
                    <a class="" routerLink="/ueber-uns/kontakt">
                      <i class="item-title" class="fa fa-comments-o" aria-hidden="true"></i>
                      <br />
                      <span class="item-subtitle">Kontakt</span>
                    </a>
                  </div>

                  <ng-container *ngIf="isAuthenticated | async; else login">
                    <div class="header-item">
                      <a
                        class=""
                        routerLink="/mein-terrassendach"
                        #CdkOverlayOriginUser="cdkOverlayOrigin"
                        cdkOverlayOrigin>
                        <i class="item-title" class="fa fa-user-o text-success" aria-hidden="true"></i>
                        <br />
                        <span class="item-subtitle">Mein Konto</span>
                      </a>
                    </div>

                    <itcw-hover-overlay
                      [cdkOverlayOrigin]="CdkOverlayOriginUser"
                      [positions]="submenuPositionAccount"
                      (close)="isOpenedUser = false"
                      (open)="isOpenedUser = true">
                      <div class="p-3 user-submenu mt-n3">
                        <mat-nav-list dense>
                          <mat-list-item>
                            <div mat-line></div>
                            <a matListItemTitle class="nav-link" routerLink="/mein-terrassendach">
                              <span matLine class="text-white">{{ userName | async }}</span>
                              Mein Konto
                            </a>
                            <button matListItemMeta mat-icon-button routerLink="/mein-terrassendach">
                              <mat-icon class="text-white">admin_panel_settings</mat-icon>
                            </button>
                          </mat-list-item>
                          <mat-list-item class="nav-link">
                            <a matListItemTitle class="nav-link" routerLink="/mein-terrassendach/angebote">
                              Meine Angebote
                            </a>
                            <button matListItemMeta mat-icon-button routerLink="/mein-terrassendach/angebote">
                              <mat-icon class="text-white">local_offer</mat-icon>
                            </button>
                          </mat-list-item>
                          <mat-list-item class="nav-link">
                            <a matListItemTitle class="nav-link" routerLink="/mein-terrassendach/bestellungen">
                              Meine Bestellungen
                            </a>
                            <button matListItemMeta mat-icon-button routerLink="/mein-terrassendach/bestellungen">
                              <mat-icon class="text-white">receipt</mat-icon>
                            </button>
                          </mat-list-item>
                          <mat-list-item class="nav-link">
                            <a
                              matListItemTitle
                              class="nav-link"
                              routerLink="/authentication/logout"
                              [state]="{ local: true }">
                              Abmelden
                            </a>
                            <button matListItemMeta mat-icon-button routerLink="/authentication/logout">
                              <mat-icon class="text-white">highlight_off</mat-icon>
                            </button>
                          </mat-list-item>
                        </mat-nav-list>
                      </div>
                    </itcw-hover-overlay>
                  </ng-container>

                  <ng-template #login>
                    <div class="header-item">
                      <a
                        class=""
                        routerLink="/mein-terrassendach"
                        #CdkOverlayOriginUser="cdkOverlayOrigin"
                        cdkOverlayOrigin>
                        <!-- <i class="item-title" class="fa fa-user-o" aria-hidden="true"></i> -->
                        <mat-icon matSuffix>account_circle</mat-icon>

                        <br />
                        <span class="item-subtitle">Anmelden</span>
                      </a>
                    </div>

                    <itcw-hover-overlay
                      [cdkOverlayOrigin]="CdkOverlayOriginUser"
                      [positions]="submenuPositionAccount"
                      (close)="isOpenedUser = false"
                      (open)="isOpenedUser = true">
                      <div class="p-3 user-submenu mt-n3">
                        Mein Konto bei Mein-Terrassendach24
                        <br />
                        <a mat-button class="nav-link" routerLink="/mein-terrassendach">Anmelden</a>
                        <br />
                        <a mat-button class="nav-link" routerLink="/authentication/register">Neues Konto erstellen</a>
                      </div>
                    </itcw-hover-overlay>
                  </ng-template>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid px-0 full-width-background-row mat-elevation-z5 position-relative" style="z-index: 4">
    <div class="container-xl px-0">
      <div class="row g-0">
        <div class="col">
          <!-- Desktop -->
          <nav
            class="navbar-menu navbar-hide-small"
            (mouseover)="navBarFocus = true; navItemFocus()"
            (mouseout)="navBarFocus = false; navItemFocusOut()">
            <a
              mat-button
              class="navbar-link hover-effect navbar-hide-small"
              routerLink="/"
              (click)="navItemFocusOut(true)"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: true }">
              <mat-icon class="menu-icon-button">home</mat-icon>
            </a>
            <a
              mat-button
              routerLink="/terrassenueberdachung/wandanschluss"
              routerLinkActive="active-link"
              (click)="navItemFocusOut(true)"
              [class.hover-enabled]="isOpenedTerraceRoof"
              title="Terrassenüberdachungen Wandanschluss"
              class="main-item hover-effect navbar-hide-small"
              #CdkOverlayOriginTerraceRoof="cdkOverlayOrigin"
              cdkOverlayOrigin>
              <span
                *ngIf="promotions.get('tud') as name"
                class="badge bg-danger badge-promotion text-uppercase text-center">
                {{ name }}
              </span>
              Terrassendach
            </a>

            <itcw-hover-overlay
              [cdkOverlayOrigin]="CdkOverlayOriginTerraceRoof"
              [positions]="submenuPositionTerraceRoof"
              (close)="isOpenedTerraceRoof = false; navItemFocusOut()"
              (open)="isOpenedTerraceRoof = true">
              <div class="p-0 terrace-roof-submenu" (mouseover)="navItemFocus()">
                <!-- <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
                  title="Terrassenüberdachung Wandanschluss Kompakt XXS">Kompakt XXS<span
                    *ngIf="promotions.get('tudk') as name"
                    class="ms-1 badge bg-danger text-uppercase">{{name}}</span></a>
              </h3> -->
                <h3>
                  <a
                    class="d-block header-category-header text-nowrap"
                    routerLink="/terrassenueberdachung/wandanschluss/einsteiger-xs"
                    title="Terrassenüberdachung Wandanschluss Einsteiger XS">
                    Einsteiger XS
                    <span *ngIf="promotions.get('tude') as name" class="ms-1 badge bg-danger text-uppercase">
                      {{ name }}
                    </span>
                  </a>
                </h3>
                <h3>
                  <a
                    class="d-block header-category-header text-nowrap"
                    routerLink="/terrassenueberdachung/wandanschluss/premium-xl"
                    title="Terrassenüberdachung Wandanschluss Premium XL">
                    Premium XL
                    <span class="align-top badge bg-warning text-uppercase">Bestseller</span>
                    <span *ngIf="promotions.get('tud') as name" class="ms-1 badge bg-danger text-uppercase">
                      {{ name }}
                    </span>
                  </a>
                </h3>
                <h3>
                  <a
                    class="d-block header-category-header text-nowrap"
                    routerLink="/terrassenueberdachung/wandanschluss/superior-xxl"
                    title="Terrassenüberdachung Wandanschluss Superior XXL">
                    Superior XXL
                    <span *ngIf="promotions.get('tudsp') as name" class="ms-1 badge bg-danger text-uppercase">
                      {{ name }}
                    </span>
                  </a>
                </h3>
              </div>
            </itcw-hover-overlay>

            <a
              mat-button
              routerLink="/terrassenueberdachung/freistehend"
              routerLinkActive="active-link"
              (click)="navItemFocusOut(true)"
              [class.hover-enabled]="isOpenedTerraceRoofFreistehend"
              title="Terrassenüberdachungen Freistehend"
              class="main-item hover-effect navbar-hide-small"
              #CdkOverlayOriginTerraceRoofFreistehend="cdkOverlayOrigin"
              cdkOverlayOrigin>
              <span
                *ngIf="promotions.get('tudfs') as name"
                class="badge bg-danger badge-promotion text-uppercase text-center">
                {{ name }}
              </span>
              Freistehend
            </a>

            <itcw-hover-overlay
              [cdkOverlayOrigin]="CdkOverlayOriginTerraceRoofFreistehend"
              [positions]="submenuPositionTerraceRoof"
              (close)="isOpenedTerraceRoofFreistehend = false; navItemFocusOut()"
              (open)="isOpenedTerraceRoofFreistehend = true">
              <div class="p-0 terrace-roof-submenu" (mouseover)="navItemFocus()" (mouseout)="navItemFocusOut()">
                <h3>
                  <a
                    class="d-block header-category-header text-nowrap"
                    routerLink="/terrassenueberdachung/freistehend/premium-xl"
                    title="Terrassenüberdachung Freistehend Premium XL">
                    Premium XL
                    <span *ngIf="promotions.get('tudpfs') as name" class="ms-1 badge bg-danger text-uppercase">
                      {{ name }}
                    </span>
                  </a>
                </h3>
                <h3>
                  <a
                    class="d-block header-category-header text-nowrap"
                    routerLink="/terrassenueberdachung/freistehend/schiebedach"
                    title="Terrassenüberdachung Freistehend Schiebedach">
                    Schiebedach
                    <span *ngIf="promotions.get('tudsfs') as name" class="ms-1 badge bg-danger text-uppercase">
                      {{ name }}
                    </span>
                  </a>
                </h3>
                <h3>
                  <a
                    class="d-block header-category-header text-nowrap"
                    routerLink="/terrassenueberdachung/freistehend/flachdach-xl"
                    title="Terrassenüberdachung Freistehend Flachdach XL">
                    Flachdach XL
                    <span *ngIf="promotions.get('tudfdf') as name" class="ms-1 badge bg-danger text-uppercase">
                      {{ name }}
                    </span>
                  </a>
                </h3>
              </div>
            </itcw-hover-overlay>

            <a
              mat-button
              routerLink="/terrassenueberdachung/schiebedach"
              routerLinkActive="active-link"
              (click)="navItemFocusOut(true)"
              title="Terrassenüberdachung Schiebedächer"
              class="main-item hover-effect navbar-hide-small">
              <span
                *ngIf="promotions.get('tuds') as name"
                class="badge bg-danger badge-promotion text-uppercase text-center">
                {{ name }}
              </span>
              Schiebedach
            </a>

            <a
              mat-button
              routerLink="/terrassenueberdachung/flachdach"
              routerLinkActive="active-link"
              (click)="navItemFocusOut(true)"
              [class.hover-enabled]="isOpenedTerraceRoofFlachdach"
              title="Flachdach Terrassenüberdachung"
              class="main-item hover-effect navbar-hide-small"
              #CdkOverlayOriginTerraceRoofFlachdach="cdkOverlayOrigin"
              cdkOverlayOrigin>
              <span
                *ngIf="promotions.get('tudfd') as name"
                class="badge bg-danger badge-promotion text-uppercase text-center">
                {{ name }}
              </span>
              Flachdach
            </a>

            <itcw-hover-overlay
              [cdkOverlayOrigin]="CdkOverlayOriginTerraceRoofFlachdach"
              [positions]="submenuPositionTerraceRoof"
              (close)="isOpenedTerraceRoofFlachdach = false; navItemFocusOut()"
              (open)="isOpenedTerraceRoofFlachdach = true">
              <div class="p-0 terrace-roof-submenu" (mouseover)="navItemFocus()" (mouseout)="navItemFocusOut()">
                <h3>
                  <a
                    class="d-block header-category-header text-nowrap"
                    routerLink="/terrassenueberdachung/flachdach/xl"
                    title="Terrassenüberdachung Flachdach XL">
                    Flachdach XL
                    <span *ngIf="promotions.get('tudfdxl') as name" class="ms-1 badge bg-danger text-uppercase">
                      {{ name }}
                    </span>
                  </a>
                </h3>
                <h3>
                  <a
                    class="d-block header-category-header text-nowrap"
                    routerLink="/terrassenueberdachung/flachdach/xxl"
                    title="Terrassenüberdachung Flachdach XXL">
                    Flachdach XXL
                    <span class="align-top badge bg-warning text-uppercase">NEU</span>
                    <span *ngIf="promotions.get('tudfdxxl') as name" class="ms-1 badge bg-danger text-uppercase">
                      {{ name }}
                    </span>
                  </a>
                </h3>
              </div>
            </itcw-hover-overlay>

            <a
              mat-button
              routerLink="/terrassenueberdachung/lamellendach"
              routerLinkActive="active-link"
              title="Lamellendach Terrassenüberdachung"
              class="main-item hover-effect navbar-hide-small">
              <span
                *ngIf="promotions.get('tudl') as name"
                class="badge bg-danger badge-promotion text-uppercase text-center">
                {{ name }}
              </span>
              Lamellendach
            </a>

            <a
              mat-button
              routerLink="/terrassenueberdachung/gartenzimmer"
              routerLinkActive="active-link"
              (click)="navItemFocusOut(true)"
              title="Terrassenüberdachung Gartenzimmer"
              class="main-item hover-effect navbar-hide-small">
              <span
                *ngIf="promotions.get('tudg') as name"
                class="badge bg-danger badge-promotion text-uppercase text-center">
                {{ name }}
              </span>
              Gartenzimmer
            </a>

            <a
              mat-button
              routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
              routerLinkActive="active-link"
              (click)="navItemFocusOut(true)"
              title="Terrassenüberdachung Wandanschluss Kompakt XXS"
              class="main-item hover-effect navbar-hide-small">
              <span class="badge bg-danger badge-promotion text-uppercase text-center">Sale %</span>
              Aktionsmodell
            </a>

            <!-- <a
              mat-button
              routerLink="/terrassenueberdachung/lagerverkauf"
              routerLinkActive="active-link"
              (click)="navItemFocusOut(true)"
              title="Terrassenüberdachung Lagerverkauf - sofort verfügbare Terrassenüberdachungen"
              class="main-item hover-effect navbar-hide-small">
              <span class="badge bg-danger badge-promotion text-uppercase text-center">Neu</span>
              Lagerverkauf</a
            > -->

            <!-- <a
              mat-button
              routerLink="/gartenhaus/standard"
              routerLinkActive="active-link"
              (click)="navItemFocusOut(true)"
              title="Gartenhaus"
              class="main-item hover-effect navbar-hide-small">
              <span class="badge bg-danger badge-promotion text-uppercase text-center">Neu</span>
              Gartenhaus</a
            > -->

            <!-- <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
                  title="Terrassenüberdachung Wandanschluss Kompakt XXS">Kompakt XXS<span
                    *ngIf="promotions.get('tudk') as name"
                    class="ms-1 badge bg-danger text-uppercase">{{name}}</span></a>
              </h3> -->

            <!-- <a mat-button routerLink="/ueber-uns/faq" class="main-item hover-effect navbar-hide-small">Häufige Fragen</a>
          <a mat-button routerLink="/referenzbilder" class="main-item hover-effect navbar-hide-small">Referenzbilder</a> -->

            <div class="flex-spacer"></div>

            <!-- <a mat-button title="Service" [matMenuTriggerFor]="mainMenu" (menuOpened)="fixDisappearIOSBug()"
            class="main-item hover-effect navbar-hide-small">Service
          </a> -->
          </nav>
          <!-- Mobile -->
          <nav class="navbar-small navbar-show-small">
            <a mat-button class="text-white navbar-link" routerLink="/">
              <mat-icon>home</mat-icon>
            </a>
            <div class="flex-spacer"></div>
            {{ appTitleService.pageTitle }}
            <div class="flex-spacer"></div>

            <a class="menu-element" mat-icon-button [matMenuTriggerFor]="mainMenu" (menuOpened)="fixDisappearIOSBug()">
              <mat-icon>menu</mat-icon>
            </a>
          </nav>
        </div>
      </div>

      <!-- Menu -->
      <mat-menu #mainMenu>
        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/wandanschluss"
          (menuOpened)="fixDisappearIOSBug()"
          title="Terrassenüberdachung Wandanschluss">
          <span>Terrassendach</span>
          <span *ngIf="promotions.get('tud') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>
        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/freistehend"
          title="Terrassenüberdachung Freistehend">
          <span>Freistehend</span>
          <span *ngIf="promotions.get('tudf') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>
        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/schiebedach"
          title="Terrassenüberdachung Schiebedach">
          <span>Schiebedach</span>
          <span *ngIf="promotions.get('tuds') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>

        <a
          class="hide-if-desktop"
          mat-menu-item
          [matMenuTriggerFor]="terraceRoofsFlachdach"
          (menuOpened)="fixDisappearIOSBug()"
          title="Terrassenüberdachung Flachdach">
          <span>Flachdach</span>
          <span *ngIf="promotions.get('tudfdw') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>

        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/lamellendach"
          title="Terrassenüberdachung Lamellendach">
          <span>Lamellendach</span>
          <span *ngIf="promotions.get('tudl') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>

        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/gartenzimmer"
          title="Terrassenüberdachung Gartenzimmer">
          <span>Gartenzimmer</span>
          <span *ngIf="promotions.get('tudg') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>

        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
          title="Aktionsmodell">
          <span>Aktionsmodell</span>
          <span class="ms-1 badge bg-danger text-uppercase">Sale %</span>
        </a>

        <!-- <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/lagerverkauf"
          title="Terrassenüberdachung Lagerverkauf - sofort verfügbare Terrassenüberdachungen">
          <span>Lagerverkauf</span>
          <span class="ms-1 badge bg-danger text-uppercase">Neu</span>
        </a> -->

        <mat-divider class="hide-if-desktop"></mat-divider>

        <a mat-menu-item routerLink="/ueber-uns/kontakt">
          <span>Kontakt</span>
        </a>
        <a mat-menu-item routerLink="/ueber-uns/ueber-uns">
          <span>Über uns</span>
        </a>

        <mat-divider></mat-divider>

        <a mat-menu-item routerLink="/ueber-uns/montageservice">
          <span>Montageservice</span>
        </a>
        <a mat-menu-item routerLink="/ueber-uns/versandinformationen">
          <span>Versandinformationen</span>
        </a>
        <a mat-menu-item routerLink="/ueber-uns/zahlungsarten">
          <span>Zahlungsarten</span>
        </a>

        <!-- <a mat-menu-item routerLink="/referenzbilder">
          <span>Referenzbilder</span>
        </a> -->
        <a mat-menu-item routerLink="/ueber-uns/faq">
          <span>Häufige Fragen</span>
        </a>

        <mat-divider></mat-divider>
        <a mat-menu-item routerLink="/ueber-uns/datenschutz">
          <span>Datenschutz</span>
        </a>
        <a mat-menu-item routerLink="/ueber-uns/garantiebedingungen">
          <span>Garantiebedingungen</span>
        </a>
        <a mat-menu-item routerLink="/ueber-uns/agb">
          <span>AGB</span>
        </a>
        <a mat-menu-item routerLink="/ueber-uns/impressum">
          <span>Impressum</span>
        </a>
        <a mat-menu-item routerLink="/ueber-uns/widerruf">
          <span>Widerruf</span>
        </a>
      </mat-menu>

      <mat-menu #terraceRoofsWandanschluss="matMenu">
        <!-- <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
        title="Terrassenüberdachung Wandanschluss Kompakt XXS">
        <span>Kompakt XXS</span>
        <span *ngIf="promotions.get('tudk') as name" class="ms-1 badge bg-danger text-uppercase">{{name}}</span>
      </a> -->
        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/wandanschluss/einsteiger-xs"
          title="Terrassenüberdachung Wandanschluss Einsteiger XS">
          <span>Einsteiger XS</span>
          <span *ngIf="promotions.get('tude') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>
        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/wandanschluss/premium-xl"
          title="Terrassenüberdachung Wandanschluss Premium XL">
          <span>Premium XL</span>
          <span class="badge bg-warning text-uppercase">Bestseller</span>
          <span *ngIf="promotions.get('tud') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>
        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/wandanschluss/superior-xxl"
          title="Terrassenüberdachung Wandanschluss Superior XXL">
          <span>Superior XXL</span>
          <span *ngIf="promotions.get('tudsp') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>
      </mat-menu>

      <mat-menu #terraceRoofsFlachdach="matMenu">
        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/flachdach/xl"
          title="Terrassenüberdachung Flachdach XL">
          <span>Flachdach XL</span>
          <span *ngIf="promotions.get('tudfdw') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>
        <a
          class="hide-if-desktop"
          mat-menu-item
          routerLink="/terrassenueberdachung/flachdach/xxl"
          title="Terrassenüberdachung Flachdach XXL">
          <span>Flachdach XXL</span>
          <span *ngIf="promotions.get('tudfdxxl') as name" class="ms-1 badge bg-danger text-uppercase">{{ name }}</span>
        </a>
      </mat-menu>
    </div>
  </div>
</header>
<div
  class="content-overlay"
  (@backdropOpenClose.start)="backdropStart()"
  (@backdropOpenClose.done)="backdropDone()"
  [@backdropOpenClose]="showBackdrop ? 'backdropOpen' : 'backdropClosed'"
  [style.height.px]="backdropHeight"></div>
